<template>
  <v-app>
    <v-container>
    <h1>Privacy</h1>
    <br>
    <p><i>Scope AI Inc. Privacy Policy</i></p>
    <br>
    <p><i>Last Updated: April 7, 2021</i></p>
    <br>
    <p><b>OUR COMMITMENT TO PRIVACY</b></p>
    <p>Scope AI Inc. (“<b>Scope AI</b>”, “<b>we</b>”, or “<b>us</b>”) is committed to respecting and protecting your privacy. Scope AI has developed this privacy policy (“<b>Privacy Policy</b>) to help to protect your personal information. This Privacy Policy explains how we gather and use your information and applies to any user using the Site or Services (as hereinafter defined) who uses our web site located at <a href="https://privacy.baby-go.com">privacy.baby-go.com</a>, mobile applications, widgets, ads, and commerce services, or any service we make available to you, (the “<b>Services</b>”) or any individuals, business partner-specific, merchant-specific, city-specific, or other area-specific web sites we offer that link to or reference this Privacy Policy (collectively, the “<b>Site</b>”) and when you receive electronic communications from us. </p>
    <p> Please read this Privacy Policy carefully and review it periodically for the latest information about our privacy practices. <b>By using the Site or the Services, you agree to the terms of this Privacy Policy and consent to the use of your information by Scope AI as set forth herein. If you do not agree with the practices described in this Privacy Policy, please do not provide us with your personal information or interact with the Site and/or Services.</b></p>
    <p> We will routinely update this Privacy Policy to clarify our practices and to reflect new or different privacy practices, such as when we add new services, functionality or features to the Site. If we make any material changes, we will notify you by e-mail (sent to the e-mail address provided by you to us) or by means of notice on the Site prior to the change becoming effective. You can determine when this Privacy Policy was last revised by referring to the date it was “Last Updated” above. If you do not agree to the revised Privacy Policy, please stop using the Site and/or Services. Any new features, Services or Products included on the Site are subject to this Privacy Policy. </p>
    <p><b>THE INFORMATION WE COLLECT</b></p>
    <p>We collect only personal information that is relevant and necessary to the purpose of the Site and Services. This information allows us to provide you with a personalized and efficient experience. We collect the following types of information from our users: </p>
    <p><b>1. Personally Identifiable Information</b></p><br>
    <p>We receive and store any information you enter on our Site or provide to us in any other way, including by e-mail. When you send an e-mail to Scope AI or provide it through one of the Sites, we may retain your e-mail address and the contents of the e-mail you send. Please note that your use of your specific e-mail provider is governed by such provider’s then applicable terms of use and privacy policy. </p>
    <p><b>User Account Registration</b>: In order for you to use parts of the Site or the Services you may have to complete a registration form and create a user account (a “<b>User Account</b>”). As part of this registration process and in other circumstances, such as participation in promotional offers, we may require select personally identifiable information (“<b>Personally Identifiable Information</b>”) such as your name, e-mail address and payment card information. By visiting your User Account settings, you can correct, amend, add or delete certain personal information on our Site. You may also be able to add other optional information to your User Account from time to time. If you choose to provide us with any optional Personally Identifiable Information, you consent to us using the same in accordance with this Privacy Policy. </p>
    <p><b>Order Information</b>: For each order you place through the Site and/or Services, you will be required to provide certain information, including, but not limited to your name, address, phone number, and e-mail (collectively, the “<b>Order Information</b>”). </p>
    <p><b>Payment Information</b>: If you are entitled to payment by Scope AI or make payment to us, we may require and may receive certain financial information in order to make payment to you or to receive payment from you, including, but not limited to relevant third-party payment processor account information, direct deposit banking information, or other information required to facilitate payment to you (the “<b>Financial Information</b>”). Rest assured that we will not share your Financial Information with any third-party except as required in order to facilitate such payments in accordance with your agreement with us. All payments made to Scope AI through the Site are processed by Stripe Inc. and by making any payments to Scope AI through the Site, you are agreeing to be bound by the Stripe terms and conditions (available <a href="https://stripe.com/en-ca/checkout/legal">here</a>) and consenting to the collection and use of your information in accordance with Stripe’s privacy policy (available <a href="https://stripe.com/en-ca/privacy">here</a>), as both may be modified from time to time. </p>
    <p><b>2. Personally Identifiable Information</b></p>
    <p>From time to time, we may also ask you to provide us with (or you may choose to provide us with) non-personally identifiable information, that is, data that is not intended to allow us to identify you personally, such as your first name or postal code. This information may be aggregated and used for our internal purposes or for creating reports for third-party advertisers and partners, or otherwise shared with third parties for any lawful purpose, without your permission. No third party is intended to be able to know who you are as a result of the use of this information. </p>
    <p>We also may automatically collect Site and Service usage information, such as the numbers and frequency of users to the Site and/or Services, pages visited and similar data. This data is used in the aggregate, that is, as a statistical measure, and not in a manner that is intended to identify you personally. Aggregate information generally is collected through the use of cookies as discussed below. This aggregate information helps Scope AI determine general usage patterns and modify the Site and/or Services in order to improve your experience. This type of aggregate information may be shared with third parties at any time. </p>
    <p>In addition to our use of non-personally identifiable information as described herein, we may permit certain third-party companies to help us tailor advertising that we think may be of interest to you based on your use of the Services and to otherwise collect and use non-personally identifiable data about your use of the Site and/or Services. </p>
    <p><b>3. Passive Gathering of Information Electronically</b></p>
    <p>We receive and store certain types of information whenever you interact with the Site or the Services. Scope AI and its authorized agents automatically receive and record certain “traffic data” on their server logs from your browser, including your IP address, cookie information, and the page you requested. Scope AI uses this traffic data to help diagnose problems with its servers, analyze trends and administer the Site and the Services. </p>
    <p><b>CHANGING YOUR INFORMATION</b></p>
    <p>You can change your personal information at any time by visiting <a href="https://privacy.baby-go.com">privacy.baby-go.com</a> after logging in. </p>
    <p><b>REMOVAL OF INFORMATION</b></p>
    <p>Upon request, Scope AI will use its commercially reasonable efforts to delete your personal information from the Site and our databases. Notwithstanding the foregoing, it may be impossible to delete all of your information without some residual (non-personally identifiable) information stored in backups and records of deletions. You can e-mail us at help@baby-go.com to request that your personal information be deleted from the Site and our servers. Please note that you may not be able to access the Site or the Services after we have deleted your information. Please note that we may not be able to continue to provide the Services in the event that you wish to have your personal information removed from the Site and our databases. In the event that termination of the Services is required in order to remove your personal information, termination will be effective thirty (30) days from the date of your request for removal. </p>
    <p><b>HOW WE USE INFORMATION INTERNALLY</b></p>
    <p>When you provide us with your information, we may use such information for a number of purposes, including:</p>
    <p><ul><li>To facilitate communication between Scope AI, our agents and you. This includes, without limitation, responding to your questions or comments, sending e-mails concerning our products and services, or contacting you for feedback regarding us, our advertisers or our business partners. You can choose to unsubscribe from marketing-based e-mails at any time. This also includes the facilitation of communications between you, Scope AI and any third party service providers required to provide the Services to you.</li><li>To administer your User Account, process payments, and provide products or services to you.</li><li>To provide information you have requested to you.</li><li>To provide you with important functionality changes to the Site or Services, new Services and special offers from Scope AI that we think you will find valuable. We maintain a record of the services you receive from us and we may ask you for additional information so that we can serve you better.</li><li>To customize, analyze, adjust and improve the Site or Services. We may from time to time conduct research, requesting input from our visitors to help us improve our website, products and services in an effort to serve you better. Your participation in such surveys is voluntary, and in some cases you may have the option to participate anonymously. However, your name, address, email and telephone number may be required to take advantage of bonuses or other benefits which are available to survey participants, in which case your contact information will only be used for notification and fulfillment purposes.</li><li>To enforce our agreements with you, prevent fraud and other prohibited or illegal activities, for other legally permissible purposes and generally to ensure that we comply with applicable laws.</li></ul></p>
    <p><b>DISCLOSURE OF PERSONALLY IDENTIFIABLE INFORMATION TO THIRD PARTIES</b></p>
    <p>Rest assured that we neither rent nor sell your Personally Identifiable Information to anyone and that we will share your Personally Identifiable Information only as described below. Note that if we intend to use your Personally Identifiable Information for purposes not identified herein, these other purposes will be described to you at the time of collection or before using such information. <b>We will not use your Personally Identifiable Information for any purpose for which we do not have your consent.</b></p>
    <p>In general, third party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, certain third party service providers (such as payment gateways) have their own privacy policies in respect of the information we are required to provide to them for your purchase transaction. For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers. In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located. As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act. Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service. </p>
    <p><b>4. Fulfilment of Services</b></p>
    <p>We may retain other companies and individuals to perform certain functions on our behalf. Examples include, but are not limited to, companies and individuals who: (i) administer the Scope AI accounts; (ii) perform related services required for the provision of the Services; (iii) process payments; (iv) conduct surveys or promotional activities; (v) deliver products or services; or (vi) provide customer support, web hosting, internet service, and data analysis. Such third parties may be provided with access to information needed to perform their functions but may not use such information for any other purpose. </p>
    <p><b>5. Protection of Scope AI and Others</b></p>
    <p>We may release Personally Identifiable Information when we believe in good faith that release is necessary to comply with a law; to enforce or apply our terms of use and other policies; or to protect the rights, property, or safety of Scope AI, our employees, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction. </p>
    <p><b>6. Business Transfers</b></p>
    <p>In some cases, we may choose to buy or sell assets. In these types of transactions, user information is typically one of the business assets that are transferred. Moreover, if Scope AI, or substantially all of its assets, were acquired, user information would be one of the assets transferred. </p>
    <p><b>7. Scope AI Personnel</b></p>
    <p>Scope AI personnel and authorized consultants and/or contractors may have access to Personally Identifiable Information, where such access is necessary in the normal course of the Scope AI business. </p>
    <p><b>IMPORTANT DISCLOSURES, PRACTICES AND CONTACT INFORMATION</b></p>
    <p><b>8. Third Party Links</b></p>
    <p>We are not responsible for the practices employed by web sites linked to or from our Site or Services, nor for the information or content contained therein. Please remember that when you use a link or go from our Site or Services to another web site, including those sites that have a link on our Site or Services, you are subject to that other web site’s own rules and policies. Please read over those rules and policies before proceeding. </p>
    <p><b>9. Data Security</b></p>
    <p>We maintain a variety of commercially reasonable safeguards designed to protect your Personally Identifiable Information from unauthorized access and disclosure. For example, we use industry accepted tools and techniques to protect against unauthorized access to our systems. </p>
    <p>You should be aware, however, that Scope AI has no control over the security of other web sites on the Internet that you might visit, even where such other site is linked to our Site or Services. If you share your computer or use a computer that is accessed by the general public, remember to sign off and close your browser window when you have finished your session. This will help to ensure that others cannot access your information. </p>
    <p>We want you to feel confident using the Site and Services. However, no system can be completely secure. Therefore, although we take commercially reasonable steps to secure your information, we do not promise, and you should not expect, that your information, searches or other communications always will remain secure. In the event of a breach of the confidentiality or security of your information, we will notify you if reasonably possible and as reasonably necessary so that you can take appropriate protective steps. We may notify you under such circumstances using the e-mail address(es) we have on record for you. You should also take care with how you handle and disclose your information. </p>
    <p><b>10. Children</b></p>
    <p>You must be the age of majority in your jurisdiction or older to register to use the Site or Services. As a result, we do not intentionally collect information about children under the age of 13. If we learn that Scope AI has collected information from a child under the age of 13, we will delete that information as quickly as possible. If you are under the age of majority in your jurisdiction, you are not permitted to use the Site and the Services or to give information about yourself to the Site. </p>
    <p><b>11. Non-Secure Website Areas</b></p>
    <p>You should be aware that if you voluntarily disclose Personally Identifiable Information on non-secure areas of Site, that information can be collected and correlated and used by third parties and may result in unsolicited messages from third parties. Such activities are beyond the control of Scope AI and you are solely responsible for your activities in this respect. </p>
    <p><b>12. Users from Outside Canada</b></p>
    <p>Scope AI is located in Canada. This Privacy Policy is governed by the law of the Province of Ontario and the federal laws of Canada applicable therein. </p>
    <p><b>13. Hosting</b></p>
    <p>The Site is hosted by GoDaddy Inc. All data is stored through Amazon Web Service’s data storage services, databases and general applications. </p>
    <p><b>14. Cookies</b></p>
    <p>When you access or use the Site and/or Services, Scope AI may use industry-wide technologies such as “cookies” and Flash (or similar technologies), which stores certain information on your computer (“<b>Local Storage</b>”) and which will allow us to enable automatic activation of certain features and make your Site or Service experience much more convenient and effortless. The cookies used by the Site or Service are created per session, does not include any information about you, other than your session key and are removed as your session ends (usually after 24 hours). It is easy to prohibit the Local Storage. Most browsers will allow you to erase cookies from your computer’s hard drive, block acceptance of cookies, or receive a warning before a cookie is stored. In order to erase or disable the Local Storage option in Flash you should use the settings option of Flash according to the specific instructions provided by the technology provider. However, if you block or erase cookies, or change the settings of Flash, your online experience may be limited. </p>
    <p>We also use certain third-party cookies. These are a different kind of cookies, stored on your computer by third parties, rather than by Scope AI. This kind of cookie is only read (and not altered by the Service) each time you visit the Service, and also when you visit certain websites or applications that uses similar cookies. Third-party cookies usually store only Non-personally identifiable information, such as the web pages you have visited, the duration of your browsing, etc. </p>
    <p><b>15. Contacting Us</b></p>
    <p>If you have any questions about Scope AI generally, this Privacy Policy or the Site, including if you would like to review your personal information please feel free to contact us by sending an email to help@baby-go.com or by writing us at 15 Grand Marshall Dr unit 1 ground floor, Scarborough, ON M1B 5N6. </p>
    <p><b>16. PIPEDA</b></p>
    <p>This website is subject to the Personal Information Protection and Electronic Documents Act. This legislation closely regulates our handling of your personal information. You can find more information on this Act through the Government of Canada site, http://www.canada.gc.ca. If you are not satisfied with our response to your privacy concerns, you may wish to contact the Privacy Commissioner via <a href="http://www.priv.gc.ca/">www.priv.gc.ca</a> or by calling 1-800-267-0441. </p>
    
    <v-footer padless>
      <v-col
        class="text-center"
      >
        {{ new Date().getFullYear() }} — <strong>Scope AI Inc.</strong>
      </v-col>
    </v-footer>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style scoped>
p {
  color: #adadad;
}
</style>>